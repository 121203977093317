.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mr-1 {
  margin-right: 15px;
}

.custom-navbar {
  background: radial-gradient(circle at 10% 20%, rgb(215, 223, 252) 0%, rgb(255, 255, 255) 0%, rgb(215, 223, 252) 84%);
}

body {
  font-family: 'Arial', 'Helvetica', 'Times New Roman';
  margin: 0;
}

body,
html {
  overflow-x: hidden;
}


.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #13274f;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
  z-index: 10;
}

.back-to-top:hover {
  background-color: #0056b3;
}

.custom-navbar-brand {
  display: flex;
  align-items: center;
}

.custom-logo {
  height: 60px;
  /* Adjust the height as needed */
  width: 150px;
  margin-right: 30px;
  margin-left: 30px;
  /* Adjust the margin as needed */
}

.carousel-img {
  height: 85vh;
}

/* service card animation */
.service-cards {
  flex: 0 50%;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
    0 5px 15px 0 rgba(37, 44, 97, 0.15);
  border-radius: 15px;
  margin: 8px;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  /* min-height: 265px; */
  transition: 0.7s;
}

.service-cards :hover {
  background: #597e9e !important;
  color: #ffffff;
  transform: scale(1.01);
  z-index: 10000;
}

.service-cards :hover ::before {
  background: rgb(85 108 214 / 10%) !important;
  z-index: 0;
  opacity: 0.5;

}

.service-cards :hover .service-title,
.service-cards :hover .service-description {
  color: #fff;
}

.service-cards :before {
  content: "";
  position: absolute;
  background: rgba(142, 160, 239, 0.05);
  width: 170px;
  height: 400px;
  z-index: 0;
  transform: rotate(42deg);
  right: -56px;
  top: 223px;
  border-radius: 35px;
}

.service-cards :hover .solu_description button {
  background: #fff !important;
  color: #309df0 !important;
}

.hover_color_bubble_service {
  position: absolute;
  /* background: #13274f !important; */
  width: 150rem;
  height: 150rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 27rem;
  border-radius: 50%;
  transform: rotate(-36deg) !important;
  left: -18rem;
  transition: 0.7s;
}

.hover_color_bubble-1 {
  position: absolute;
  background: #13274f !important;
  width: 150rem;
  height: 150rem;
  left: 0;
  right: 0;
  z-index: -100;
  top: 20rem;
  border-radius: 50%;
  transform: rotate(-36deg) !important;
  left: -18rem;
  transition: 0.7s;
}

.hover_color_bubble-2 {
  position: absolute;
  background: #13274f !important;
  width: 150rem;
  height: 150rem;
  left: 0;
  right: 0;
  z-index: -100;
  top: 22rem;
  border-radius: 50%;
  transform: rotate(-36deg) !important;
  left: -18rem;
  transition: 0.7s;
}

.hover_color_bubble_about {
  position: absolute;
  background: #13274f !important;
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -100;
  top: 15rem;
  border-radius: 50%;
  transform: rotate(-36deg) !important;
  left: -18rem;
  transition: 0.7s;
}

.service-cards:hover .hover_color_bubble_about {
  top: 2rem !important;
  background-color: #13274f;
  opacity: 0.2;
}

.service-cards:hover .hover_color_bubble_service {
  top: 2rem !important;
  background-color: #13274f;
  opacity: 0.2;
}

.service-cards:hover .hover_color_bubble-1 {
  top: 0rem !important;
  background-color: #13274f;
  opacity: 0.2;
}

.service-cards:hover .hover_color_bubble-2 {
  top: 0rem !important;
  background-color: #13274f;
  opacity: 0.2;
}

/* feature-container  */
.feature-container {
  flex: 0 50%;
  background: #fff;
  border-radius: 15px;
  padding: 10px 15px;
  position: relative;
  z-index: 100;
  overflow: hidden;
  /* min-height: 265px; */
  transition: 0.7s;
}

.feature-container :hover {
  background: #d0ddea;
}

.feature-container :hover .hover-feature {
  position: absolute;
  background: #13274f !important;
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: 1000;
  top: 5rem;
  border-radius: 50%;
  transform: rotate(-36deg) !important;
  left: -18rem;
  transition: 0.7s;
  opacity: 0.15;
}

.hover-feature {
  position: absolute;
  background: #13274f !important;
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -100;
  top: 60rem;
  border-radius: 50%;
  transform: rotate(-36deg) !important;
  left: -18rem;
  transition: 0.7s;

}

@media screen and (max-width : 1200px) {
  .hover_color_bubble_service {
    top: 32rem !important;
  }

  .hover_color_bubble-1 {
    top: 22rem !important;
  }

  .hover_color_bubble-2 {
    top: 26rem !important;
  }
}

@media screen and (max-width : 992px) {
  .hover_color_bubble_service {
    top: 39rem !important;
  }

  .hover_color_bubble-1 {
    top: 30rem !important;
  }

  .hover_color_bubble-2 {
    top: 34rem !important;
  }
}

@media screen and (max-width : 768px) {
  .hover_color_bubble_service {
    top: 29rem !important;
  }

  .detailedService {
    margin-bottom: 1rem !important;
  }

  .hover_color_bubble-1 {
    top: 20rem !important;
  }

  .hover_color_bubble-2 {
    top: 21rem !important;
  }
}


@media screen and (max-width : 576px) {
  .hover_color_bubble_service {
    top: 39rem !important;
  }

  .detailedService {
    margin-bottom: 1rem !important;
  }

  .hover_color_bubble-1 {
    top: 22rem !important;
  }

  .hover_color_bubble-2 {
    top: 25rem !important;
  }
}

.bttn {
  background-color: #13274f !important;
  height: 40px;
}